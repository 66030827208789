import React from 'react';
import EpisodeSelector from '../EpisodeSelector/EpisodeSelector';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import VideoPlayerOptions from '../VideoPlayer/VideoPlayerOptions';
import {VideoInterface} from '../../helpers/video';
import * as styles from './styles.css';

const WatchLatestShow: React.SFC<PropTypes> = ({curVideoOptions, onVideoChanged, vid, videos}) => (
  <div id="watchLatestShow" className={styles.watchLatestShow}>
    <div className={styles.header}>
      <div className={styles.headerLeft}>
        <div className={styles.heading}>
          Watch the <span>latest</span> show
        </div>
      </div>
      <div className={styles.headerRight}>
        <EpisodeSelector onChange={onVideoChanged} vid={vid} videos={videos} />
      </div>
    </div>

    <VideoPlayer curVideoOptions={curVideoOptions} vid={vid} onVideoChanged={onVideoChanged} />
  </div>
);

interface PropTypes {
  curVideoOptions: VideoPlayerOptions;
  onVideoChanged: (vid: number) => Promise<void>;
  vid: number;
  videos: VideoInterface[];
}

export default WatchLatestShow;
