import axios from 'axios';
import {VideoInterface} from '../helpers/video';

const cachedVideOptions: CachedMap = {};

export const fetchVideos = async ({
  baseUrl,
  geo,
  hasBlockNumber,
  limit
}: FetchVideosProps): Promise<VideoInterface[]> => {
  const geoParam = geo ? `&geo=${geo}` : '';

  try {
    const response = await axios.get(
      `${baseUrl}/api/player/channel/dailymailtv/playlist.json?hasBlockNumber=${hasBlockNumber}&limit=${limit}${geoParam}`
    );

    const videos: VideoInterface[] = response.data;

    return videos;
  } catch (error) {
    console.error('Error retrieving videos data', error);

    return [];
  }
};

export const fetchCurVideoOptions = async (vid: number, baseUrl: string): Promise<string> => {
  if (cachedVideOptions[vid]) {
    return cachedVideOptions[vid];
  }

  try {
    const response = await axios.get(`${baseUrl}/api/player/${vid}/video-options.json`);

    cachedVideOptions[vid] = JSON.stringify(response.data);

    return JSON.stringify(response.data);
  } catch (error) {
    console.error(`Error retrieving current video data for id ${vid}. Error: `, error);

    return '';
  }
};

interface FetchVideosProps {
  geo?: string;
  baseUrl: string;
  hasBlockNumber: boolean;
  limit?: number;
}

interface CachedMap {
  [key: string]: string;
}
