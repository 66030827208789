import React, {Component} from 'react';
import queryString from 'query-string';
import WatchLatestShow from '../WatchLatestShow/WatchLatestShow';
import VideoPlayerOptions from '../VideoPlayer/VideoPlayerOptions';
import CityFinder from '../CityFinder/CityFinder';
import TopStories from '../TopStories/TopStories';
import {VideoInterface} from '../../helpers/video';
import {fetchCurVideoOptions} from '../../api/video';
import * as styles from './styles.css';

export default class ChannelPage extends Component<PropTypes> {
  public state: StateInterface;
  public parsedQueryString: ParsedQueryString;
  public baseUrl: string;

  constructor(props: PropTypes) {
    super(props);

    this.baseUrl = this.props.BASE_URL;
    this.parsedQueryString = {};

    this.state = {
      curVideoOptions: {},
      vid: -1
    };
  }

  public changeCurrentURL = (vid: string) => {
    this.parsedQueryString = {
      ...this.parsedQueryString,
      vid
    };

    try {
      window.history.pushState(null, undefined, `?${queryString.stringify(this.parsedQueryString)}`);
    } catch (error) {
      console.error('Error updating the URL', error);
    }
  };

  public updateVideoOptions = (videoOptions: string, firstLoad = false): VideoPlayerOptions => {
    const parsedVideoOptions = JSON.parse(videoOptions);
    parsedVideoOptions.linkBaseURL = location.href;
    const socialShareConfig = parsedVideoOptions.plugins && parsedVideoOptions.plugins['social-share'];

    if (socialShareConfig) {
      Object.assign(socialShareConfig, {
        qs: {
          diggit: {ito: 'diggit_dmtv_channel_page'},
          email: {ito: 'email_dmtv_channel_page'},
          facebook: {ito: 'facebook_dmtv_channel_page'},
          google: {ito: 'google_dmtv_channel_page'},
          linkedin: {ito: 'linkedin_dmtv_channel_page'},
          pinterest: {ito: 'pinterest_dmtv_channel_page'},
          reddit: {ito: 'reddit_dmtv_channel_page'},
          twitter: {ito: 'twitter_dmtv_channel_page'}
        }
      });
    }

    parsedVideoOptions.forceAutoplay = true;

    if (firstLoad) {
      parsedVideoOptions.initialVideo = true;
    }

    return parsedVideoOptions;
  };

  public onVideoChanged = async (vid: number, scrollIntoView = true, firstLoad = false) => {
    this.changeCurrentURL(String(vid));

    const curVideoOptions = this.updateVideoOptions(await fetchCurVideoOptions(vid, this.baseUrl), firstLoad);

    if (scrollIntoView) {
      const watchLatestShowEl = document.getElementById('watchLatestShow');
      if (
        watchLatestShowEl &&
        window &&
        (window as DMWindow).DM &&
        (window as DMWindow).DM.molFeDomHelpers &&
        (window as DMWindow).DM.molFeDomHelpers.scrollUtils &&
        (window as DMWindow).DM.molFeDomHelpers.scrollUtils.scrollToElement
      ) {
        (window as DMWindow).DM.molFeDomHelpers.scrollUtils.scrollToElement(watchLatestShowEl);
      }
    }

    this.setState({
      curVideoOptions,
      vid
    });
  };

  public componentDidMount() {
    this.parsedQueryString = queryString.parse(location.search);

    const vid = Number(this.parsedQueryString.vid || this.props.episodeOrderedVideos[0].id);
    this.onVideoChanged(vid, false, true);
  }

  public render(): JSX.Element {
    const {AKAMAI_CITY_CODE, citiesJsonUrl, episodeOrderedVideos, episodeUnorderedVideos} = this.props;
    const {vid, curVideoOptions} = this.state;

    return (
      <div className={styles.channelPage}>
        <CityFinder citiesJsonUrl={citiesJsonUrl} cityCode={String(AKAMAI_CITY_CODE)} />

        <WatchLatestShow
          curVideoOptions={curVideoOptions}
          onVideoChanged={this.onVideoChanged}
          vid={vid}
          videos={episodeOrderedVideos}
        />

        <TopStories onVideoChanged={this.onVideoChanged} videos={episodeUnorderedVideos} />
      </div>
    );
  }
}

interface DMWindow extends Window {
  DM: {
    molFeDomHelpers: {
      scrollUtils: {
        scrollToElement: (el1: HTMLElement) => {};
      };
    };
  };
}

interface ParsedQueryString {
  [key: string]: string;
}

interface StateInterface {
  curVideoOptions: VideoPlayerOptions;
  vid: number;
}

export interface PropTypes {
  AKAMAI_CITY_CODE?: string;
  BASE_URL: string;
  citiesJsonUrl: string;
  episodeOrderedVideos: VideoInterface[];
  episodeUnorderedVideos: VideoInterface[];
}
