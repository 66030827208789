import React from 'react';
import * as styles from './styles.css';
import VideoThumb from '../VideoThumb/VideoThumb';
import {VideoInterface} from '../../helpers/video';

const TopStories: React.SFC<PropTypes> = ({onVideoChanged, videos}) => {
  return (
    <div className={styles.topStories}>
      <div className={styles.heading}>DailymailTV's Top Stories</div>
      <div className={styles.container}>
        <ul className={styles.videosContainer}>
          {videos.map((video: VideoInterface) => (
            <VideoThumb key={video.id} onVideoChanged={onVideoChanged} {...video} />
          ))}
        </ul>
      </div>
    </div>
  );
};

interface PropTypes {
  onVideoChanged: (vid: number) => Promise<void>;
  videos: VideoInterface[];
}

export default TopStories;
