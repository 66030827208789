export const getVideo = (videos: VideoInterface[], vid: number | undefined): VideoInterface | undefined => {
  if (!vid) {
    return undefined;
  }

  return videos.find((curVideo: VideoInterface) => curVideo.id === vid);
};

interface DmtvEpisodeInterface {
  number: string;
  airDate: string;
  dmtvEpisodeId: number;
}

export interface VideoInterface {
  dmtvBlockNumber: number;
  dmtvEpisode: DmtvEpisodeInterface;
  duration: number;
  headline: string;
  id: number;
  longHeadline: string;
  mediumImage: string;
  url: string;
}
