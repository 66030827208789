import React, {Component} from 'react';
const millisec = require('millisec');
import * as styles from './styles.css';

export default class VideoThumb extends Component<PropTypes> {
  public state: StateInterface;

  constructor(props: PropTypes) {
    super(props);

    const {duration, longHeadline} = this.props;
    const millisecs = millisec(duration);
    let seconds = millisecs.getSeconds();
    seconds = seconds < 10 ? `0${seconds}` : (seconds as string);
    const minutes = millisecs.getMinutes() as string;

    const BREAKING_CHAR = ':';
    let headline1: string | undefined = '';
    let headline2 = longHeadline;
    if (longHeadline.includes(BREAKING_CHAR)) {
      const headlineArray = longHeadline.split(BREAKING_CHAR);
      headline1 = headlineArray.shift();
      headline1 += BREAKING_CHAR;
      headline2 = headlineArray.join(BREAKING_CHAR);
    }

    this.state = {
      headline1,
      headline2,
      minutes,
      seconds
    };
  }

  public handleClick = () => {
    const {onVideoChanged, id} = this.props;
    onVideoChanged(id);
  };

  public render(): JSX.Element {
    const {mediumImage} = this.props;
    const {headline1, headline2, minutes, seconds} = this.state;

    return (
      <li className={styles.videoThumb} onClick={this.handleClick}>
        <div className={styles.postThumb}>
          <div className={styles.duration}>{`${minutes}:${seconds}`}</div>
          <img src={mediumImage} alt={headline1} width={308} height={173} loading="lazy" />
        </div>
        <div className={styles.postContent}>
          <div className={styles.heading}>
            <span className={styles.headline1}>{headline1}</span>
            {headline2}
          </div>
        </div>
      </li>
    );
  }
}

interface StateInterface {
  headline1: string | undefined;
  headline2: string | undefined;
  minutes: string;
  seconds: string;
}

interface PropTypes {
  duration: number;
  headline: string;
  id: number;
  longHeadline: string;
  onVideoChanged: (vid: number) => Promise<void>;
  mediumImage: string;
}
