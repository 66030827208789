export const groupStyles = {
  fontSize: '13px',
  fontWeight: 'bold',
  padding: '8px 13px',
  textTransform: 'capitalize'
};

interface StateObject {
  isFocused: boolean;
  isSelected: boolean;
}

// all the possible styles:
// https://github.com/JedWatson/react-select/blob/2654ce0505d9e3820e169109cc413778fc20f843/src/styles.js
export default {
  control: (base: object) => ({
    ...base,
    backgroundColor: 'rgba(0, 0, 0, 0)',
    borderRadius: 0,
    cursor: 'pointer',
    fontFamily: 'Graphik',
    marginTop: '12px'
  }),
  dropdownIndicator: (base: object, state: StateObject) => {
    const {isFocused} = state;

    return {
      ...base,
      ':hover': {
        color: '#ccc'
      },
      color: 'white',
      transform: isFocused ? 'rotate(180deg)' : ''
    };
  },
  groupHeading: (base: object) => ({
    ...base,
    backgroundColor: '#f5f5f5',
    color: 'black',
    display: 'flex',
    fontFamily: 'Graphik',
    height: '30px',
    justifyContent: 'space-between',
    marginBottom: '0',
    padding: 0
  }),
  input: (base: object) => ({
    ...base,
    color: 'white'
  }),
  menu: (base: object) => ({
    ...base,
    backgroundColor: 'white',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    marginTop: 0,
    zIndex: 100000
  }),
  menuList: (base: object) => ({
    ...base,
    padding: 0
  }),
  option: (base: object, state: StateObject) => {
    const {isSelected} = state;

    return {
      ...base,
      color: isSelected ? 'white' : '#333',
      cursor: 'pointer',
      fontFamily: 'Graphik',
      fontWeight: isSelected ? 'bold' : 'inherit',
      textAlign: 'left' as 'left'
    };
  },
  placeholder: (base: object) => ({
    ...base,
    color: '#ccc'
  }),
  singleValue: (base: object) => ({
    ...base,
    color: 'white'
  })
};
