import React, {Component} from 'react';
import Select from 'react-select';
import {GroupType, ValueType} from 'react-select/lib/types';
import {Option} from 'react-select/lib/filters';
import selectStyles, {groupStyles} from './selectStyles';

import {getVideo, VideoInterface} from '../../helpers/video';

const formatGroupLabel = (data: GroupType<Option>) => (
  // @ts-ignore
  <div style={groupStyles}>{data.label}</div>
);

export default class EpisodeSelector extends Component<PropTypes> {
  public state: StateInterface;

  constructor(props: PropTypes) {
    super(props);

    this.state = {
      groupedOptions: [],
      placeholder: 'Select an episode',
      selectedOption: undefined
    };

    this.state.selectedOption = this.getOptionValuesFromVideoId(props.vid);
    this.state.groupedOptions = this.getGroupedOptions(this.props.videos);
  }

  public createEpisodeLabel = (video: VideoInterface): string => {
    const {dmtvEpisode} = video;
    if (!dmtvEpisode.airDate) {
      return 'Invalid date';
    }
    const justDate = dmtvEpisode.airDate.split('T')[0];

    const timestamp = new Date(`${justDate}T00:00:00Z`);

    return timestamp.toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'short',
      timeZone: 'UTC',
      weekday: 'short',
      year: 'numeric'
    });
  };

  public getOptionValuesFromVideoId = (vid: number | undefined): Option | undefined => {
    const video = getVideo(this.props.videos, vid);
    if (video) {
      return this.getOptionValuesFromVideo(video);
    } else {
      // This video is not in the latest ordered by episode videos
      return {
        data: {},
        label: this.state.placeholder,
        value: ''
      };
    }
  };

  public getOptionValuesFromVideo = (video: VideoInterface): Option => ({
    data: {},
    label: `${this.createEpisodeLabel(video)} - Part ${video.dmtvBlockNumber}`,
    value: `${video.id}`
  });

  public getVideosByEpisodeNumberDict = (videos: VideoInterface[]): VideoEpisodesDict =>
    videos.reduce((output: VideoEpisodesDict, video: VideoInterface) => {
      const {dmtvEpisode} = video;

      const {number: episodeNum} = dmtvEpisode;
      if (!output[episodeNum]) {
        output[episodeNum] = [];
      }
      output[episodeNum].push(video);

      return output;
    }, {});

  public getOptionsFromVideos = (videos: VideoInterface[]): Option[] =>
    videos.map((video) => this.getOptionValuesFromVideo(video));

  public getGroupedOptions = (videos: VideoInterface[]): Array<GroupType<Option>> => {
    // 1. Create a Dictionary that maps the video episode number with the Video
    const videoEpisodesDict: VideoEpisodesDict = this.getVideosByEpisodeNumberDict(videos);

    // 2 return videoGroups array
    return (
      Object.entries(videoEpisodesDict)
        .map(([episodeNum, episodeVideos]) => ({
          label: this.createEpisodeLabel(episodeVideos[0]),
          number: Number(episodeNum),
          options: this.getOptionsFromVideos(episodeVideos)
        }))
        // sort new episodes first
        .sort((a, b) => b.number - a.number)
    );
  };

  // unsafe_componentwillreceiveprops is deprecated as seen here:
  // https://reactjs.org/docs/react-component.html#unsafe_componentwillreceiveprops
  // and the suggested way of dealing with props changes is in componentDidUpdate
  public componentDidUpdate(prevProps: PropTypes) {
    if (prevProps.vid !== this.props.vid) {
      this.setState({
        selectedOption: this.getOptionValuesFromVideoId(this.props.vid)
      });
    }
  }

  public handleChange = (option: ValueType<Option>) => {
    // react select allows multiselection
    // not using multiselection here. Still we have to manage that eventuality
    if (option && !Array.isArray(option)) {
      const vid = Number(option.value);
      this.props.onChange(vid, false);
    }
  };

  public render(): JSX.Element {
    const {groupedOptions, placeholder, selectedOption} = this.state;

    return (
      <Select
        classNamePrefix="react-select"
        formatGroupLabel={formatGroupLabel}
        isSearchable={false}
        // @ts-ignore
        onChange={this.handleChange}
        options={groupedOptions}
        placeholder={placeholder}
        // @ts-ignore
        styles={selectStyles}
        // @ts-ignore
        value={selectedOption}
      />
    );
  }
}

interface PropTypes {
  vid: number;
  videos: VideoInterface[];
  onChange: (vid: number, scrollIntoView: boolean) => Promise<void>;
}

interface StateInterface {
  groupedOptions: Array<GroupType<Option>>;
  placeholder: string;
  selectedOption: Option | undefined;
}

interface VideoEpisodesDict {
  [key: string]: VideoInterface[];
}
