import React from 'react';
import {render} from 'react-dom';
import ChannelPage, {PropTypes as ChannelPagePropsInterface} from '../shared/components/ChannelPage/ChannelPage';

const AKAMAI_DIV: HTMLDivElement | null = document.querySelector('[data-akamai-citycode]');
if (!AKAMAI_DIV) {
  console.error('No AKAMAI city found');
} else {
  const AKAMAI_CITY_CODE: string = AKAMAI_DIV.getAttribute('data-akamai-citycode') as string;

  Array.from(document.querySelectorAll('[data-xpmodule-creation-config]')).forEach((element) => {
    const dataProps = element.getAttribute('data-xpmodule-creation-config');

    if (dataProps) {
      const props: ChannelPagePropsInterface = JSON.parse(dataProps);
      props.AKAMAI_CITY_CODE = AKAMAI_CITY_CODE;

      render(<ChannelPage {...props} />, element);
    }
  });
}
